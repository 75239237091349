import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

// import Header from './header'
import '../scss/main.scss'
// import './layout.css'

const MainNav = [
  {
    name: 'Episodes',
    link: '/#episodes'
  },{
    name: 'About',
    link: '/#about'
  },{
    name: 'Picks',
    link: '/#picks'
  },{
    name: 'Contact',
    link: '/#contact'
  },
]

const FooterNav = [
  // {
  //   name: 'Support Monthly on Patreon',
  //   class: 'patreon',
  //   link: 'https://www.patreon.com/thingsigotwrong'
  // }, 
  {
    name: 'Support with Crypto on Coinbase',
    class: 'coinbase',
    link: 'https://commerce.coinbase.com/checkout/86d7c9cc-bf39-4fbe-8994-6ba11a14e59f'
  }, {
    name: 'Support using PayPal',
    class: 'paypal',
    link: 'https://www.paypal.me/thingsigotwrong'
  }, {
    name: 'Leave a Review on Apple Podcasts',
    class: 'apple-podcasts',
    link: 'https://podcasts.apple.com/us/podcast/things-i-got-wrong-at-trivia-a-pub-quiz-game-show-with-friends/id1436232415'
  },
]

const SocialLinks = [
  {
    name: 'Instagram',
    link: 'http://instagram.com/ThingsIGotWrong',
    external: true,
  }, {
    name: 'Twitter',
    link: 'http://twitter.com/ThingsIGotWrong',
    external: true,
  }, {
    name: 'Pinterest',
    link: 'https://www.pinterest.com/ThingsIGotWrong',
    external: true,
  },
]

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}

    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Things I Got Wrong at Trivia | A Pub Quiz Game Show with Friends' },
            { name: 'keywords', content: 'podcast, trivia, pub quiz, trivia podcast, best trivia podcasts' },
          ]}
        >
          <meta name="description" content={data.site.siteMetadata.description} />
          <meta name="image" content={data.site.siteMetadata.image} />

          <html lang="en" />
        </Helmet>

        <header id="header" className="header row">
          <div className="header--container">
            <nav id="main-nav" className="nav">
              <ul>
                {MainNav.map(link => (
                  <li key={link.name}>
                    <a href={link.link}
                      className={link.name.toLowerCase()} alt={link.name}>{link.name}</a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
      	</header>

        <div>
          {children}
        </div>

        <footer id="footer" className="footer">
          <div className="footer-top row">
            <div className="container row">
              <h4>Other Links</h4>
              <div className="footer__column">
                <ul>
                  {FooterNav.map(link => (
                    <li key={link.name}>
                      <a href={link.link} 
                      className={link.class.toLowerCase()}  
                      target="_blank" 
                      rel="noopener noreferrer" 
                      alt={link.name}
                    >
                      {link.name}
                    </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="container row">
              <h4>Social</h4>
              <div className="footer__column">
                <ul>
                  {SocialLinks.map(link => (
                    <li key={link.name}>
                      <a href={link.link} 
                         className={link.name.toLowerCase()} target="_blank" 
                         rel="noopener noreferrer" 
                         alt={link.name}
                      >
                        {link.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <div className="container row">

              <div className="copyright" style={{color: '#f8f8f8', textAlign: 'right', width: '40vw'}}>
                <span>&copy; Things I Got Wrong {new Date().getFullYear()}. All Rights Reserved.</span>
              </div>
            </div>
          </div>

        </footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
